<template>
  <div :class="`div-header ${light ? 'head-light' : 'head-dark'}`">
    <div class="div-headerBox">
      <div class="header-left">
        <logo :light="light" :logoTitle="logoTitle"></logo>
        <slot name="left">
          <div :class="`left-box ${light ? 'head-light' : 'head-dark'}`">
            <span v-if="showTitle" class="title-text my-title">{{
              titleName
            }}</span>
            <div class="menu-wrap" v-else></div>
          </div>
        </slot>
      </div>
      <div class="header-right">
        <slot name="right"></slot>
        <account :light="light"></account>
      </div>
    </div>
    <div class="div-header-menu">
      <el-menu
        :default-active="activeRoute"
        @select="selectMenu"
        :router="true"
        class="el-menu-demo"
        style="border-bottom:none"
        mode="horizontal"
        background-color="#fff"
        text-color="#333"
        active-text-color="#3D5AFE"
      >
        <template v-for="menuItem in menuItems">
          <el-submenu
            v-if="menuItem.submenus"
            :key="menuItem.title"
            :index="menuItem.path"
            class="my-submenu"
          >
            <template slot="title">{{ menuItem.title }}</template>
            <el-menu-item
              v-for="childItem in menuItem.submenus"
              :key="childItem.title"
              :index="childItem.path"
              >{{ childItem.title }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            class="my-submenu-item"
            :key="menuItem.title"
            :index="menuItem.path == 'visual_analysis' ? '#' : menuItem.path"
            >{{ menuItem.title }}</el-menu-item
          >
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import Account from "@/components/base/Account";
export default {
  name: "HeaderMenu",
  components: { Logo, Account },
  props: {
    titleName: {
      type: String,
      default: "",
    },
    light: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    logoTitle: {
      type: String,
      default: "",
    },
    menuItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    moreMenus: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeIndex: "home",
    };
  },
  computed: {
    // activeMenu: {
    //   get() {
    //     return this.$store.state.user.currentPage;
    //   }
    // },
    activeRoute() {
      const route = this.$route;
      if (route.matched.length) {
        const hasPathRoute = route.matched.find((el) => !!el.path);
        return hasPathRoute.path;
      }
      return route.path;
    },
  },
  methods: {
    selectMenu(index) {
      if (index === "#") {
        //   let routeData = this.$router.resolve({
        //     path: "visual_analysis".replace("#", ""),
        //   });
        //   window.open(routeData.href, "_blank");
        var yourWindow = window.open();
        yourWindow.opener = null;
        yourWindow.location = process.env.VUE_APP_VISUAL_URL;
        yourWindow.target = "_blank";
      }
    },
    handleRegister() {
      // this.$router.push("/register");
    },
  },
};
</script>
<style lang="scss">
.title-text {
  font-size: 18px;
  font-weight: 600;
  color: rgba(74, 74, 74, 1);
  text-shadow: 0px 2px 4px rgba(222, 227, 231, 1);

  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.div-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  .div-headerBox {
    width: 1100px;
  }
}
.head-dark {
  .title-text {
    color: #ffffff;
    text-shadow: none;
  }
}
.left-box {
  height: 64px;
  display: flex;
  align-items: center;
}
.menus {
  margin-left: 20px;
  z-index: 998;
}
.myitem {
  height: 56px !important;
  cursor: pointer;
  z-index: 999;
}
.menu-wrap {
  display: flex;
  margin-left: 40px;
  height: 64px;
  line-height: 64px;
  z-index: 998;
  align-items: center;
  max-width: 608px;
}
// .my-submenu >>> {
//   height: 55px;
//   line-height: 55px;
//   /deep/ .el-menu-item {
//     font-size: 16px;
//     height: 55px;
//     line-height: 55px;
//   }
// }
.my-submenu-item {
  font-size: 16px;
  height: 63px !important;
  line-height: 63px !important;
}
.el-menu-demo {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  .my-submenu-item {
    flex: 1;
    text-align: center;
  }
}
.el-menu::before,
.el-menu::after {
  display: none;
}
</style>
